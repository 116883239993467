export default [
  '/api/organization/get-organization-of-region',
  '/api/frontend-component/queryFrontendComponentByOrganizationPaged',
  '/api/vs-project-main/transfer-project',
  '/api/dictionary/pub/create-dictionary',
  '/api/user-account/get-oss-policy',
  '/api/organization/query-organization-dup-vo-by-organization-dup',
  '/api/dictionary/pub/add-tag',
  '/api/organization/query-organization-vs-project-vo-by-organization-project-list-paged',
  '/api/vs-project-main/query-project-uuid-name-by-user',
  '/api/frontend-component/update-subscribe-version',
  '/api/vs-project-main/query-project-by-organization-paged',
  '/api/organization/query-organization-vs-user-vo-by-organization-user-list-paged',
  '/api/dictionary/pub/update-dictionary',
  '/api/organization/complete-invite',
  '/openapi/assist/project/create',
  '/api/user-account/get-account-info',
  '/api/frontend-component/version/subscribe',
  '/api/organization/query-organization-vs-project-vo-by-organization-project-list-waterfall',
  '/api/organization/query-organization-vs-user-vo-by-organization-user-list-waterfall',
  '/api/vs-project-main/create-project',
  '/api/dictionary/pub/create-category',
  '/api/user-account/pub/get-login-method-sso-by-usertypes-and-userids',
  '/api/bookmark/pub/create',
  '/api/user/name/list',
  '/api/organization/add-user-to-organization',
  '/api/organization/role',
  '/api/vs-project-main/generate-invite-code',
  '/api/vs-project-main/query-user-by-project-paged',
  '/api/vs-project-resource/delete-project-resource',
  '/api/vs-project-resource/delete-resource-item',
  '/api/organization/remove-user-from-organization',
  '/api/user-account/update-account-status',
  '/api/project/pub/get-project-role',
  '/api/organization/update-organization',
  '/api/frontend-component/delete-frontend-component',
  '/api/user-account/pub/get-by-ids',
  '/api/frontend-component/delete-frontend-component-version',
  '/api/project/pub/get-tenant',
  '/api/recent/pub/update',
  '/api/frontend-component/update-frontend-component',
  '/openapi/assist/project/add-user',
  '/api/organization/transfer-project-apply',
  '/api/mocker/message/organization/user_registered_mo',
  '/api/frontend-component/publish-frontend-component-version',
  '/api/frontend-component/queryFrontendVersionByOrganizationPaged',
  '/api/organization/query-organization-user-info-by-user',
  '/api/region/update-region',
  '/api/user-account/pub/get-account-by-id',
  '/api/bookmark/pub/delete',
  '/api/frontend-component/queryFrontendComponentListPaged',
  '/api/dictionary/pub/init',
  '/api/user-account/phone-and-verify-login',
  '/api/organization/region-admin-add-organization',
  '/api/vs-project-resource/update-project-resource',
  '/api/vs/dictionary/get/with/version',
  '/api/vs/dictionary/category/get',
  '/api/vs/dictionary/category/submit',
  '/api/bookmark/pub/get-all',
  '/api/vs-project-main/update-project',
  '/api/frontend-component/get-frontend-component-by-id',
  '/api/vs-project-main/add-user-to-project',
  '/api/module-progress/get-module-evaluate-detail',
  '/api/module-progress/setting-module-progress-expect-time',
  '/api/user-account/query-query-account-vo-by-account-by-organization-paged-by-{}{}',
  '/api/user-account/logout',
  '/api/vs-project-resource/update-resource-item',
  '/api/vs-project-main/query-project-tag-by-organization',
  '/api/organization/get-organization-by-id',
  '/api/vs-project-main/visit-project',
  '/api/recent/pub/get-by-user',
  '/api/user-account/get-account-by-id',
  '/api/project-main/is-project-owner',
  '/api/vs-project-main/remove-user-from-project',
  '/api/project-main/has-project-permission',
  '/api/vs-project-main/get-orgid-by-project',
  '/api/vs-project-main/update-project-init-status',
  '/api/region/query-account-by-region-paged',
  '/api/frontend-component/create-frontend-component',
  '/api/vs-project-resource/create-project-resource',
  '/api/organization/queryOrganizationWithProjectUser',
  '/api/organization/complete-transfer-project',
  '/api/frontend-component/queryVersionSubscribeByProjectPaged',
  '/api/dictionary/pub/get-category-by-project',
  '/api/project/list/by/user',
  '/api/project/get/by/name',
  '/api/module-progress/setting-progress-evaluate',
  '/api/project/pub/project-auth',
  '/api/organization/query-organization-vs-project-by-user',
  '/api/organization/get-invite-info',
  '/api/dictionary/pub/get-dictionary-by-project',
  '/api/organization-entrance-web/create-project-to-organization',
  '/api/vs-project-main/complete-invite',
  '/api/vs-project-resource/query-resource-by-project',
  '/api/module-progress/setting-workload-evaluate',
  '/api/organization/transfer-project-confirm',
  '/api/organization/query-organization-by-user-id-paged',
  '/openapi/assist/project/create/insigma',
  '/api/project/pub/is-project-owner',
  '/api/user-account/pub/get-login-method-sso-by-usernames',
  '/api/user-account/update-account',
  '/api/recent/pub/create',
  '/openapi/assist/project/remove-user',
  '/api/region/query-account-by-organization-role',
  '/api/organization/query-project-transfer-record-by-user-paged',
  '/api/vs/dictionary/delete/new',
  '/api/organization/generate-invite-code',
  '/api/module-progress/setting-module-progress-point-status',
  '/api/user-account/send-verify-code',
  '/api/vs-project-main/query-project-with-organization-by-user',
  '/api/region/get-region-with-tenant-enterprise-by-id',
  '/api/organization/update-user-in-organization',
  '/api/sso/sp/consumer/saml',
  '/api/vs/dictionary/category/delete',
  '/openapi/assist/project/set-admin',
  '/api/organization/generate-transfer-project-code',
  '/api/dictionary/pub/delete-dictionary',
  '/api/organization/get-transfer-project-info',
  '/api/vs-project-main/get-project-by-id',
  '/api/organization/create-organization',
  '/api/vs-project-resource/create-resource-item',
  '/api/vs-project-main/get-invite-info',
  '/api/organization/query-organization-vs-project-vo-by-organization-vs-project-by-user',
  '/api/organization/get-all-role-tag',
  '/api/frontend-component/version/unsubscribe',
  '/api/frontend-component/create-frontend-component-version',
  '/api/user-account/user-password-login',
  '/api/user-account/pub/get-login-method-sso-by-roles',
  '/api/user_action/query/count',
  '/api/user_action/query',
  '/api/user_action/org/all',
  '/api/user_action/user/all',
];
