import { getOrganization, selectCommon, setUserInfo } from '@/app/common';
import { RootState } from '@/app/store';
import Icon from '@/components/icon';
import SuspenseLayout from '@/components/SuspenseLayout';
import User from '@/components/user';
import ChooseModule from '@/modules/organization/choose';
import services from '@/services';
import { CSSVariablesStyle } from '@/themes/CSSVariablesStyle';
import { Theme } from '@/type';
import { DownloadOutlined } from '@ant-design/icons';
import {
  Button,
  Image,
  Layout,
  Popover,
  theme as AntdTheme,
} from '@df/toco-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import Logo from '../../public/logo.png';
import LogoDark from '../../public/logo_dark.png';
import DownloadApp from './DownloadApp';
import styles from './index.module.css';

const { Header, Content, Sider } = Layout;

export const renderRole = (role) => {
  switch (role) {
    case 'SUPER_ADMIN':
      return '超级管理员';
    case 'ADMIN':
      return '管理员';
    case 'PARTICIPATE':
      return '组织成员';
    case 'TEMPORARY':
      return '临时成员';
  }
};

const App: React.FC = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = AntdTheme.useToken();
  const navigate = useNavigate();
  const theme = useSelector((state: RootState) => state?.common?.theme);
  const { currentOrg, userInfo, organization, rootOrg } =
    useSelector(selectCommon);

  const dispatch = useDispatch();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([
    'self',
    'organization',
    'region',
  ]);

  const [chooseOrgVisible, setChooseOrgVisible] = useState<boolean>(false);
  const isRegionManage =
    rootOrg?.role === 'ADMIN' || rootOrg?.role === 'SUPER_ADMIN';

  const isActiveRegionManage = window.location.pathname.includes('/region');
  const menuItems = useMemo(() => {
    return [
      // {
      //   key: 'account',
      //   icon: <Icon type="toco-m-zuzhiguanli" />,
      //   label: '个人信息',
      //   path: '/account',
      // },
      {
        key: 'self',
        icon: <Icon type="toco-m-zuzhiguanli" size={20} />,
        label: '工作台',
        path: '/workbench',
        children: [
          {
            key: 'self-project',
            icon: <Icon type="toco-m-xiangmuguanli" size={20} />,
            label: '我的项目',
            path: '/workbench/project',
          },
          {
            key: 'self-organization',
            icon: <Icon type="toco-m-zizuzhiguanli" size={20} />,
            label: '我的组织',
            path: '/workbench/organization',
          },
        ],
      },
      {
        key: 'organization',
        icon: <Icon type="toco-m-xiangmuguanli" size={20} />,
        label: (
          <ChooseModule>
            <div className={styles?.['organization-info']}>
              <div className={styles?.['organization-name']}>
                {currentOrg?.name}
              </div>
            </div>
          </ChooseModule>
        ),
        path: '/organization',
        children: [
          {
            key: 'organization-role',
            label: (
              <span className={styles?.['display-role-word']}>
                {renderRole(currentOrg?.role)}
              </span>
            ),
            className: styles?.['display-role'],
            disabled: true,
          },
          {
            key: 'organization-project',
            icon: <Icon type="toco-m-xiangmuguanli" size={20} />,
            label: '项目列表',
            path: '/project',
          },
          {
            key: 'organization-member',
            icon: <Icon type="toco-m-gongsirenzheng" size={20} />,
            label: '成员列表',
            path: '/member',
          },
          {
            key: 'organization-setting',
            icon: <Icon type="toco-m-shezhi" size={20} />,
            label: '设置',
            path: '/organization',
          },
        ],
      },

      {
        key: 'region',
        icon: <Icon type="toco-m-quanyuguanli" size={20} />,
        label: '全域管理',
        path: '/region',
        children: [
          {
            key: 'region-organization',
            icon: <Icon type="toco-m-zizuzhiguanli" size={20} />,
            label: '子组织管理',
            path: '/region/organization',
          },
          {
            key: 'region-member',
            icon: <Icon type="toco-m-chengyuanguanli1" size={20} />,
            label: '人员管理',
            path: '/region/member',
          },
          {
            key: 'region-setting',
            icon: <Icon type="toco-m-shezhi" size={20} />,
            label: '设置',
            path: '/region/setting',
          },
        ],
      },

      // {
      //   key: 'company',
      //   icon: <Icon type="toco-m-gongsirenzheng" />,
      //   label: '公司认证',
      //   path: '/company',
      //   disabled: true,
      // },
    ];
  }, [currentOrg?.name, currentOrg?.role]);

  const getItemInfo = (key) => {
    let target;
    const recursion = (list) => {
      list?.forEach((item) => {
        if (item?.key === key) {
          target = item;
        } else if (item?.children) {
          recursion(item?.children);
        }
      });
    };
    recursion(menuItems);
    return target;
  };

  const handleClick = (node) => {
    const { key } = node;
    const target = getItemInfo(key);
    if (target) {
      setSelectedKeys([node?.key]);
      navigate(target?.path ?? '');
    }
  };
  const handleToggleOrg = useCallback(() => {
    navigate(`/organization/${currentOrg?.organizationId}/home?tab=project`);
  }, [navigate, currentOrg]);

  const handleToRootManage = useCallback(() => {
    navigate(`/region`);
  }, [navigate]);

  useEffect(() => {
    dispatch((getOrganization as any)(true));
  }, [dispatch]);

  useEffect(() => {
    const href = window.location.href;
    const target = menuItems?.find((item) => href?.indexOf(item?.path) >= 0);
    if (target) {
      setSelectedKeys([target?.key]);
    }
  }, [menuItems]);

  const getUserInfo = useCallback(async () => {
    const res = await services.UserAccountController_getAccountInfo_11ced6();
    dispatch(setUserInfo(res?.data));
  }, [dispatch]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  return (
    <>
      <CSSVariablesStyle />
      <Layout className={styles?.layout}>
        <Header className={styles?.header}>
          <div className={styles?.['header-left']}>
            <div className={styles?.logo}>
              <Image
                width={125}
                height={20}
                className={styles.logoImage}
                src={theme === Theme.DEFAULT ? Logo : LogoDark}
                preview={false}
              />
            </div>
            {/* <ChooseModule>
              <div className={styles?.['organization-info']}>
                <div className={styles?.['organization-name']}>
                  {currentOrg?.name}
                </div>
              </div>
            </ChooseModule> */}
            {!currentOrg?.id &&
            (!organization || organization?.length === 0) ? null : (
              <React.Fragment>
                <div className={styles?.['top-menu']}>
                  <div className={styles?.['top-menu-item']}>
                    {/* <div className={styles?.['top-menu-item-tab']}>工作台</div> */}
                    {isRegionManage ? (
                      <div
                        onClick={handleToRootManage}
                        className={
                          styles?.[
                            isActiveRegionManage
                              ? 'top-menu-item-tab-active'
                              : 'top-menu-item-tab'
                          ]
                        }
                      >
                        全域管理
                      </div>
                    ) : null}
                  </div>
                  <div className={styles?.['top-menu-item']}>
                    <div className={styles?.['organization-info']}>
                      <div className={styles?.['organization-name']}>
                        <span
                          className={styles?.['organization-name-word']}
                          onClick={handleToggleOrg}
                        >
                          {currentOrg?.regionRoot ? (
                            <Icon type="toco-m-xinjianyu" size={20} />
                          ) : null}
                          我的组织
                        </span>
                        <span className={styles?.['organization-name-choose']}>
                          <ChooseModule></ChooseModule>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          <div className={styles?.['header-right']}>
            <Popover content={<DownloadApp />}>
              <div>
                <Button
                  className={styles.download}
                  type="link"
                  icon={<DownloadOutlined size={20} />}
                >
                  下载App
                </Button>
              </div>
            </Popover>
            <User data={userInfo} />
          </div>
        </Header>
        <Layout>
          <Content
            className={styles?.content}
            style={{
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <SuspenseLayout>
              <Outlet />
            </SuspenseLayout>
          </Content>
        </Layout>
        {/* <Layout>
          <Sider
            className={styles?.sider}
            width={200}
            style={{ background: colorBgContainer }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              style={{ height: '100%', borderRight: 0, fontSize: 14 }}
              items={menuItems}
              selectedKeys={selectedKeys}
              onClick={handleClick}
              openKeys={openKeys}
              expandIcon={null}
              className={styles?.['layout-menu']}
            />
          </Sider>
          <Layout>
            <Content
              className={styles?.content}
              style={{
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <SuspenseLayout>
                <Outlet />
              </SuspenseLayout>
            </Content>
          </Layout>
        </Layout> */}
      </Layout>
    </>
  );
};

export default App;
